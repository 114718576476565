import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import GooglePlaces from '../Additional/GooglePlaces';
import Alert from '../Additional/Alert';
import { v4 as uuidv4 } from 'uuid';
import './New_Landing_Page.css';
import ClipLoader from "react-spinners/FadeLoader";
import '../styles.css';
import { createNewLandingPageQuote, createActivityQuoteDB, retrieveCustomer } from '../API/Model';
import axios from 'axios'
import Navigation from '../Overlays/Navigation';
import moment from "moment";

class New_Landing_Page extends Component {

    constructor(props) {
        super(props);
        localStorage.clear();

        this.state = {
            addressInfo: {},
            disable: true,
            alert_state: false,
            error_message: "",
            width: window.innerWidth,
            height: window.innerHeight,
            errorHeight: 85,
            mainErrorHeight: 65,
            cov_a_limit: null,
            isFocused: false,
            loading: false,
        }

        this.setAddressInfo = this.setAddressInfo.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onCovALimitChange = this.onCovALimitChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.prepareQuote = this.prepareQuote.bind(this);
        this.databaseUpdate = this.databaseUpdate.bind(this);
        localStorage.setItem('session_id', uuidv4());
        localStorage.setItem('current_page', "Home");
    }

    async componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.setState({ width: window.innerWidth, height: window.innerHeight });
        const res = await axios.get('https://geolocation-db.com/json/')
        localStorage.setItem('user_ip', res.data.IPv4);
        localStorage.setItem('status', "STR");
        createActivityQuoteDB("Inspection", "PL", "Inspection page loaded");
    }

    handleResize() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    formatCurrency(num) {
        return num ? "$" + num.toLocaleString("en-US", { minimumFractionDigits: 0 }) : "";
    }

    parseCurrency(str) {
        return str ? parseInt(str.replace(/\D/g, "")) : null;
    }

    onCovALimitChange(e) {
        const newValue = this.parseCurrency(e.target.value.replace(/,/g, ''));
        if (newValue <= 99999999) {
            this.setState({ cov_a_limit: newValue })
        }
        else if (!newValue) {
            this.setState({ cov_a_limit: null })
        }
    }

    handleBlur(e) {
        const { cov_a_limit } = this.state;
        if (!cov_a_limit) {
            this.setState({ cov_a_limit: null });
        }
        else if (cov_a_limit < 100000) {
            this.setState({ cov_a_limit: 100000 });
        }
    }

    async databaseUpdate() {

        await fetch(window.location.href.substr(0, window.location.href.lastIndexOf("/") + 1) + 'portal/createQuoteDB', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Quote_Number: localStorage.getItem('quote_number'),
                Address: this.state.addressInfo.street_number + " " + this.state.addressInfo.street,
                Zip_code: this.state.addressInfo.zip,
                Quote_Status_code: "STR",
                Session_id: localStorage.getItem('session_id'),
            })
        })
    }

    prepareQuote() {
        const quote_DTOInsured = [
            {
                EntityTypeCd: "Individual",
                PreferredDeliveryMethod: "",
                PartyInfo: [
                    {
                        PartyTypeCd: "InsuredParty",
                        Status: "Active",
                        PersonInfo: [
                            {
                                BestWayToContact: "",
                            }
                        ],
                        Addr: [
                            {
                                AddrTypeCd: "InsuredMailingAddr",
                                Addr1: this.state.addressInfo.street_number + " " + this.state.addressInfo.street,
                                PostalCode: this.state.addressInfo.zip,
                                City: this.state.addressInfo.city,
                                StateProvCd: "VA"
                            },
                            {
                                AddrTypeCd: "InsuredBillingAddr",
                                Addr1: this.state.addressInfo.street_number + " " + this.state.addressInfo.street,
                                PostalCode: this.state.addressInfo.zip,
                                City: this.state.addressInfo.city,
                                StateProvCd: "VA"
                            },
                            {
                                AddrTypeCd: "InsuredLookupAddr",
                                Addr1: this.state.addressInfo.street_number + " " + this.state.addressInfo.street,
                                PostalCode: this.state.addressInfo.zip,
                                City: this.state.addressInfo.city,
                                StateProvCd: "VA"
                            },
                            {
                                AddrTypeCd: "InsuredResidentAddr",
                                Addr1: this.state.addressInfo.street_number + " " + this.state.addressInfo.street,
                                PostalCode: this.state.addressInfo.zip,
                                City: this.state.addressInfo.city,
                                StateProvCd: "VA"
                            },
                            {
                                AddrTypeCd: "InsuredPriorAddr",
                                Addr1: "",
                                PostalCode: "",
                                City: "",
                                StateProvCd: "VA"
                            }
                        ]
                    },
                ],
                IndexName: ""
            }
        ]

        const current_date = new Date();
        const eff_date = moment().format("YYYYMMDD");
        const exp_date = moment().add(1, "years").format("YYYYMMDD");
        const quote = {
            ExternalValidateBeanExist: "No",
            ByPassCustomerCreation: "No",
            DTOApplication: [{
                Status: "In Process",
                TypeCd: "Quote",
                CustomerRef: "",
                DTOTransactionInfo: {
                    TransactionCd: "New Business",
                    SourceCd: "Innovation",
                    TransactionShortDescription: "New Business",
                    TransactionLongDescription: "New Business",
                    TransactionEffectiveDt: (
                        eff_date
                    ),
                    "TransactionNumber": "0"
                },
                DTOLine: [{
                    StatusCd: "Active",
                    LineCd: "Homeowners",
                    RatingService: "ManualRate",
                    "DTORisk": [
                        {
                            "TypeCd": "Homeowners",
                            "DTOBuilding": [
                                {
                                    "CovALimit": this.state.cov_a_limit,
                                },
                            ],
                        },
                    ],
                }],
                DTOInsured: quote_DTOInsured,
                DTOBasicPolicy: [{
                    EffectiveDt: (
                        eff_date
                    ),
                    ExpirationDt: (
                        exp_date
                    ),
                    PolicyVersion: "1",
                    RenewalTermCd: "1 Year",
                    SubTypeCd: "HO5",
                    PolicyForm: "HO5",
                    PolicySource: "New Business Application",
                    RecentMoveIn: "No",
                    TransactionNumber: "1",
                    Description: "Preferred Homeowners",
                    ProviderNumber: "001",
                    ControllingStateCd: "VA",
                    ProductVersionIdRef: "masov-VA-PreferredHomeowners-v01-00-06",
                    CarrierGroupCd: "MASOV",
                    BusinessSourceCd: "DirectPortal",
                    TransactionCd: "New Business"
                }]
            }]
        }
        return quote;
    }

    async handleSubmit(e) {
        createActivityQuoteDB("Inspection", "NAV", "Continue button");

        this.setState({
            mainErrorHeight: 65,
            loading: true,
        })
        const cov_a = this.state.cov_a_limit;
        if (cov_a !== null) {
            window.localStorage.setItem("cov_a", cov_a.toString());
        } else {

        }
        window.localStorage.setItem("existing_customer", "NO");
        this.onButtonClick();

        const quote = this.prepareQuote();

        createActivityQuoteDB("Inspection", "API_REQ", "Create quote request");

        const response = await createNewLandingPageQuote(JSON.stringify(quote));
        let quoteStatus = response;
        if (quoteStatus === "SUCCESS") {
            await this.databaseUpdate();
            createActivityQuoteDB("Inspection", "API_RES", "Create quote response - " + response);
            this.props.history.push('/InspectionInfo');
        } else if (quoteStatus != null) {
            createActivityQuoteDB("Inspection", "API_RES", "Create quote response - " + response);
            this.setState({
                error_message: quoteStatus,
                alert_state: true,
                loading: false,
            })
        }
    }


    setAddressInfo(addressInfo) {
        this.setState({
            addressInfo,
            mainErrorHeight: 65,
        })
        if (this.state.addressInfo.state !== "Virginia") {

            this.setState({
                disable: true,
                alert_state: true
            })
            this.setState({
                error_message: "We only offer policies for homes in Virginia. Thanks for checking us out.",
                errorHeight: 85,
            })

        } else {
            if (this.state.addressInfo.city
                && this.state.addressInfo.country
                && this.state.addressInfo.state
                && this.state.addressInfo.street
                && this.state.addressInfo.street_number
                && this.state.addressInfo.zip) {
                this.setState({
                    disable: false,
                    alert_state: false
                })
            } else {
                this.setState({
                    disable: true,
                    alert_state: true,
                    error_message: "We are unable complete your estimate at this time. Please contact customer service at 800-648-1794.",
                    errorHeight: 105,
                })
            }
        }

    }


    async onButtonClick() {

        createActivityQuoteDB("Inspection", "NAV", "Go button");
        window.grecaptcha.enterprise.ready(async function () {
            try {
                const token = await window.grecaptcha.enterprise.execute('6LfCsp0dAAAAAK_ovmpumPsEqegcHoMmNcxz89u3', { action: 'LOGIN' });

            } catch (e) {
                console.error("Capcha Error : ", e);
            }
        });
    }


    render() {
        const footerMessage = "What would you do with the money you save?";
        const { cov_a_limit } = this.state;

        return (
            <>
                {this.state.loading ?
                    <div className="loading">
                        <div className="loading_icon">
                            <ClipLoader color={"#003550"} loading={this.state.loading} height={20} width={5} radius={10} margin={10} />

                        </div>
                    </div>
                    :
                    <div className="box_home_wrapper">

                        <div className="offset"></div>
                        {this.state.width < 758 &&
                            <Alert status={this.state.alert_state} message={this.state.error_message} width={this.state.width - 30} height={this.state.errorHeight} margin={0} color={"#ffb3b3"} />
                        }
                        {this.state.width > 758 &&

                            <Alert status={this.state.alert_state} message={this.state.error_message} width={810} height={this.state.mainErrorHeight} margin={"auto"} marginRight={"auto"} color={"#ffb3b3"} />
                        }
						<div id="NewLandingPage_BoxTopText" className="box_top_text">
							<p className="p1_txt text-muted pl-0">Our policyholders save $2,000 on average over 5 years.</p>
							<p className="p1_txt text-muted pl-0">Estimate your savings in 5 seconds.</p>
                        </div>
                        <div className="box">
                            <GooglePlaces setAdressInfo={this.setAddressInfo} />

                            <div >
                                <div className="homeValue">
                                    <input
                                        type="text"
                                        value={cov_a_limit}
                                        value={this.formatCurrency(cov_a_limit)}
                                        onChange={this.onCovALimitChange}
                                        onBlur={this.handleBlur}
                                        placeholder="Your home value"
                                    />
                                    <span className="baner_icon_info_home baner_icon" data-tooltip="This is the cost to replace your home in case of total loss.  You can find this on your current policy as ‘Coverage A’, or the ‘Dwelling/Residence Limit’.  If you are unsure of the value, you can use the market value of your home from Zillow or another source as a rough estimate. Prior to providing a firm quote, we will conduct an inspection of your home to ensure you have the right amount of coverage." />
                                </div>
                            </div>

                            {
                                this.state.disable
                                    ? <Link to="/InspectionInfo" className="disabledCursor" onClick={(event) => event.preventDefault()}>
                                        <button disabled={this.state.disable} className="btn_page_1" onClick={this.handleSubmit} style={{ background: "#808080" }}>Go</button>
                                    </Link>
                                    : <div className="disabledCursor">
                                        {/*<Link to="/InspectionInfo" className="disabledCursor"></Link>*/}
                                        <button disabled={this.state.disable} className="btn_page_1" onClick={this.handleSubmit}>Go</button>
                                    </div>
                            }
                        </div>
                        <div className="mx-n3">
                            <footer className="container px-0 mx-0">
                                <p style={{ background: "#aaa" }} className="footer-banner-title p-1 mb-0 mt-4 font-weight-bold text-center text-white" >{footerMessage}</p>
                                {/*<figure className="footer-banner">*/}
                                <img className="w-100 footer-banner-img" alt={footerMessage} src="/images/Policy_holders_banner.png" />
                                {/*    <figcaption class="footer-banner-text">{footerMessage}</figcaption>*/}
                                {/*</figure>*/}
                                <p className="mt-2 text-center p1_txt text-muted pl-0" >The way home insurance was meant to be.</p>
                                <img className="w-100 footer-banner-img" alt={footerMessage} src="/images/Footer_image_1.png" />
                            </footer>
                        </div>

                    </div>
                }
            </>
        );
    }
}

export default withRouter(New_Landing_Page);
